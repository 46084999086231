<template>
  <div class="auth-wrapper auth-v1 px-2">
    <!--<div class="auth-inner py-2">-->
    <overlay-default
      id="olyRegister"
      :show="isLoading"
    >
      <!--class="auth-inner py-2"-->
      <div>
        <b-card class="mb-0">
          <b-card-title class="font-weight-bold mb-1 text-center">
            <logo-component class="mb-1" />
          </b-card-title>
          <b-card-text class="mb-2 text-center">
            Registro de nuevo usuario
          </b-card-text>
          <!-- form -->
          <validation-observer ref="registerFormRegister">
            <b-form
              class="auth-register-form mt-2"
              @submit.prevent
            >
              <b-form-group label-for="login-cuil">
                <validation-provider
                  name="Cuil"
                  rules="required|min:11"
                >
                  <div slot-scope="{ errors, failedRules }">
                    <cleave
                      id="block"
                      v-model="formUser.cuil"
                      class="form-control"
                      :state="errors.length > 0 ? false:null"
                      :raw="true"
                      :options="options.customDelimiter"
                      placeholder="C.U.I.L."
                    />
                    <small
                      v-if="failedRules.required"
                      class="text-danger"
                    >C.U.I.L. obligatorio.
                    </small>
                    <small
                      v-if="failedRules.min"
                      class="text-danger"
                    >C.U.I.L. incompleto.
                    </small>
                  </div>
                </validation-provider>
              </b-form-group>
              <!--<b-form-group label-for="login-cuil">
                <validation-provider
                  name="NroAfiliado"
                  rules="required|max:18"
                >
                  <div slot-scope="{ errors, failedRules }">
                    <cleave
                      id="blockNroAfiliado"
                      v-model="formUser.nroAfiliado"
                      class="form-control"
                      :state="errors.length > 0 ? false:null"
                      :raw="false"
                      :options="options.number"
                      placeholder="Número de afiliado"
                    />
                    <small
                      v-if="failedRules.required"
                      class="text-danger"
                    >Número de afiliado obligatorio.
                    </small>
                    <small
                      v-if="failedRules.max"
                      class="text-danger"
                    >Máximo 18 caracteres
                    </small>
                  </div>
                </validation-provider>
              </b-form-group>
              -->
              <!-- username -->
              <b-form-group label-for="fechaNacimiento">
                <validation-provider
                  name="FechaNacimiento"
                  rules="required|min:8"
                >
                  <div slot-scope="{ errors, failedRules }">
                    <cleave
                      id="date"
                      v-model="formUser.fechaNacimiento"
                      class="form-control"
                      :state="errors.length > 0 ? false:null"
                      :raw="true"
                      :options="options.date"
                      placeholder="Fecha Nac. DD/MM/AAAA"
                    />
                    <small
                      v-if="failedRules.required"
                      class="text-danger"
                    >Fecha de nacimiento obligatorio.
                    </small>
                    <small
                      v-if="failedRules.min"
                      class="text-danger"
                    >Fecha incompleta.
                    </small>
                  </div>
                </validation-provider>
              </b-form-group>
              <b-form-group label-for="email">
                <validation-provider
                  name="Email"
                  rules="required|email"
                >
                  <div slot-scope="{ errors, failedRules }">
                    <b-form-input
                      id="email"
                      v-model="formUser.email"
                      :state="errors.length > 0 ? false:null"
                      name="register-email"
                      placeholder="Correo electrónico"
                    />
                    <small
                      v-if="failedRules.required"
                      class="text-danger"
                    >Correo obligatorio.
                    </small>
                    <small
                      v-if="failedRules.email"
                      class="text-danger"
                    >Ingrese un correo válido.
                    </small>
                  </div>
                </validation-provider>
              </b-form-group>
              <!-- submit button -->
              <b-button
                variant="primary"
                block
                type="submit"
                @click="validationForm"
              >
                Enviar
              </b-button>
            </b-form>
          </validation-observer>

          <b-card-text class="text-center mt-2">
            <span>¿Ya tiene una cuenta? </span>
            <b-link :to="{name:'login'}">
              <span>Ingresar</span>
            </b-link>
          </b-card-text>
          <b-card-text class="text-center mt-2">
            <small>{{ versionApp }}</small>
          </b-card-text>
        </b-card>
      </div>
      <div v-if="showDetalleAsignacionModal">
        <modal-custom-default
          :show-modal-success-reset-password="showDetalleAsignacionModal"
          :modal-info="modalInfo"
          @contentModal="contentModal"
        />
      </div>
    </overlay-default>
  </div>

</template>

<script>
import {
  BCard, BLink, BCardTitle, BCardText, BForm,
  BButton, BFormInput, BFormGroup,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import Cleave from 'vue-cleave-component'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
// import flatPickr from 'vue-flatpickr-component'
import {
  authenticationResource,
} from '@/services/authenticationResource'
import LogoComponent from './LogoComponent.vue'
import ModalCustomDefault from './ModalCustomDefault.vue'

export default {
  components: {
    BCard,
    BLink,
    BCardTitle,
    BCardText,
    BForm,
    BButton,
    BFormInput,
    BFormGroup,
    LogoComponent,
    Cleave,
    // flatPickr,
    ModalCustomDefault,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      versionApp: 'v 1.3.0',
      regEmail: '',
      username: '',
      password: '',
      status: '',
      isLoading: false,
      showDetalleAsignacionModal: false,
      modalInfo: {
        modalTitle: '',
        emailTo: '',
        defMessage: '',
      },
      formUser: {
        cuil: null,
        nroAfiliado: null,
        fechaNacimiento: null,
        email: null,
      },
      // validation rules
      required,
      email,
      options: {
        block: {
          blocks: [2, 8, 1],
          uppercase: true,
        },
        date: {
          date: true,
          delimiter: '/',
          datePattern: ['d', 'm', 'Y'],
        },
        customDelimiter: {
          delimiters: ['-', '-'],
          blocks: [2, 8, 1],
          uppercase: true,
          numericOnly: true,
        },
        number: {
          numeral: false,
          blocks: [18],
          numeralPositiveOnly: true,
          numericOnly: true,
          numeralDecimalMark: '',
          delimiter: '',
        },
      },
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    myDate() {
      return this.formatDateToJavaT(this.formUser.fechaNacimiento, 'DDMMYYYY', 'YYYY-MM-DD')
    },
  },
  methods: {
    contentModal(show) {
      this.showDetalleAsignacionModal = show
      this.$router.push({ name: 'login' })
    },
    validationForm() {
      this.$refs.registerFormRegister.validate().then(success => {
        if (success) {
          this.registerUser()
        }
      })
    },
    registerUser() {
      this.isLoading = true
      const user = { ...this.formUser }
      user.fechaNacimiento = this.formatDateToJavaT(user.fechaNacimiento, 'DDMMYYYY', 'YYYY-MM-DD')
      authenticationResource().registerUser(user)
        .then(() => {
          this.sendEmailResetPassword()
        })
        .catch(error => {
          console.error(error)
          this.isLoading = false
        })
        .finally(() => {
        })
    },
    sendEmailResetPassword() {
      authenticationResource().sendEmailResetPassword(this.formUser)
        .then(resultsLoginUser => {
          // this.effectiveLogin(resultsLoginUser)
          this.modalInfo.modalTitle = 'Registro de usuario'
          this.modalInfo.defMessage = `Hemos enviado un correo a ${resultsLoginUser.email} para completar el proceso de registración.`
          this.modalInfo.emailTo = resultsLoginUser.email
          this.showDetalleAsignacionModal = true
        })
        .catch(e => {
          console.error(e)
        })
        .finally(() => {
          this.isLoading = false
        })
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
