var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"auth-wrapper auth-v1 px-2"},[_c('overlay-default',{attrs:{"id":"olyRegister","show":_vm.isLoading}},[_c('div',[_c('b-card',{staticClass:"mb-0"},[_c('b-card-title',{staticClass:"font-weight-bold mb-1 text-center"},[_c('logo-component',{staticClass:"mb-1"})],1),_c('b-card-text',{staticClass:"mb-2 text-center"},[_vm._v(" Registro de nuevo usuario ")]),_c('validation-observer',{ref:"registerFormRegister"},[_c('b-form',{staticClass:"auth-register-form mt-2",on:{"submit":function($event){$event.preventDefault();}}},[_c('b-form-group',{attrs:{"label-for":"login-cuil"}},[_c('validation-provider',{attrs:{"name":"Cuil","rules":"required|min:11"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var failedRules = ref.failedRules;
return _c('div',{},[_c('cleave',{staticClass:"form-control",attrs:{"id":"block","state":errors.length > 0 ? false:null,"raw":true,"options":_vm.options.customDelimiter,"placeholder":"C.U.I.L."},model:{value:(_vm.formUser.cuil),callback:function ($$v) {_vm.$set(_vm.formUser, "cuil", $$v)},expression:"formUser.cuil"}}),(failedRules.required)?_c('small',{staticClass:"text-danger"},[_vm._v("C.U.I.L. obligatorio. ")]):_vm._e(),(failedRules.min)?_c('small',{staticClass:"text-danger"},[_vm._v("C.U.I.L. incompleto. ")]):_vm._e()],1)}}])})],1),_c('b-form-group',{attrs:{"label-for":"fechaNacimiento"}},[_c('validation-provider',{attrs:{"name":"FechaNacimiento","rules":"required|min:8"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var failedRules = ref.failedRules;
return _c('div',{},[_c('cleave',{staticClass:"form-control",attrs:{"id":"date","state":errors.length > 0 ? false:null,"raw":true,"options":_vm.options.date,"placeholder":"Fecha Nac. DD/MM/AAAA"},model:{value:(_vm.formUser.fechaNacimiento),callback:function ($$v) {_vm.$set(_vm.formUser, "fechaNacimiento", $$v)},expression:"formUser.fechaNacimiento"}}),(failedRules.required)?_c('small',{staticClass:"text-danger"},[_vm._v("Fecha de nacimiento obligatorio. ")]):_vm._e(),(failedRules.min)?_c('small',{staticClass:"text-danger"},[_vm._v("Fecha incompleta. ")]):_vm._e()],1)}}])})],1),_c('b-form-group',{attrs:{"label-for":"email"}},[_c('validation-provider',{attrs:{"name":"Email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var failedRules = ref.failedRules;
return _c('div',{},[_c('b-form-input',{attrs:{"id":"email","state":errors.length > 0 ? false:null,"name":"register-email","placeholder":"Correo electrónico"},model:{value:(_vm.formUser.email),callback:function ($$v) {_vm.$set(_vm.formUser, "email", $$v)},expression:"formUser.email"}}),(failedRules.required)?_c('small',{staticClass:"text-danger"},[_vm._v("Correo obligatorio. ")]):_vm._e(),(failedRules.email)?_c('small',{staticClass:"text-danger"},[_vm._v("Ingrese un correo válido. ")]):_vm._e()],1)}}])})],1),_c('b-button',{attrs:{"variant":"primary","block":"","type":"submit"},on:{"click":_vm.validationForm}},[_vm._v(" Enviar ")])],1)],1),_c('b-card-text',{staticClass:"text-center mt-2"},[_c('span',[_vm._v("¿Ya tiene una cuenta? ")]),_c('b-link',{attrs:{"to":{name:'login'}}},[_c('span',[_vm._v("Ingresar")])])],1),_c('b-card-text',{staticClass:"text-center mt-2"},[_c('small',[_vm._v(_vm._s(_vm.versionApp))])])],1)],1),(_vm.showDetalleAsignacionModal)?_c('div',[_c('modal-custom-default',{attrs:{"show-modal-success-reset-password":_vm.showDetalleAsignacionModal,"modal-info":_vm.modalInfo},on:{"contentModal":_vm.contentModal}})],1):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }